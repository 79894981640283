import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import 'react-tabs/style/react-tabs.css'

import './index.css';
import App from './App';
import i18n from './i18n';
import Footer from './components/Footer/Footer';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <I18nextProvider i18n={i18n}>
    <Router>
      <React.StrictMode>
        <App />
        <Footer />
      </React.StrictMode>
    </Router>
  </I18nextProvider>
);

