import React from 'react';
import { PopupButton } from 'react-calendly';

import './MarketplaceBody.css';
import graphic1 from '../../../assets/images/graphic_01.jpg';
import graphic2 from '../../../assets/images/graphic_02.jpg';
import graphic3 from '../../../assets/images/graphic_03.jpg';

import icon1 from '../../../assets/images/icons/icon1.svg';
import icon2 from '../../../assets/images/icons/icon2.svg';
import icon3 from '../../../assets/images/icons/icon3.svg';
import { useTranslation } from 'react-i18next';

const Benefit = ({title, icon, text}) => {
    return (
        <div className="benefit">
            <img src={icon} alt={title} className="icon" />
            <h3 className="benefit-title">{title}</h3>
            <p>{text}</p>
        </div>
    )
}

const MarketplaceBody = () => {

    const { t } = useTranslation();

    return (
        <div className="marketplace-body">

            <div className="body">
                <div className="images">
                    <img src={graphic1} alt="" className="graphic" />
                    <img src={graphic2} alt="" className="graphic" />
                    <img src={graphic3} alt="" className="graphic" />
                </div>

                <div className="resume">
                    <h2 className="title">
                        ¿Estás buscando tomar decisiones estratégicas apoyadas en datos robustos y confiables? ¿Deseas obtener una ventaja competitiva decisiva en tu sector?
                    </h2>
                    <p>
                        Independientemente de cuál sea tu objetivo, nuestro equipo de Análisis de Mercado tiene la solución perfecta para ti. Sumérgete en un mundo de insights valiosos y estrategias basadas en ciencia de datos que transformarán tu enfoque comercial y te colocarán a la vanguardia del mercado.
                    </p>
                </div>
            </div>

            <div className="benefits">
                <h2>/ BENEFICIOS</h2>

                <div className="content">
                    <Benefit
                        icon={icon1}
                        title='Anális en segundos' 
                        text='Tu tiempo es un recurso invaluable, sin sacrificar la calidad ni la profundidad de los insights, nuestra tecnología de vanguardia te proporciona resultados rápidos y precisos.'
                    />
                    <Benefit
                        icon={icon2}
                        title='Datos de todo tipo' 
                        text='Datos demográficos, análisis de mercado, opiniones de clientes o información gubernamental, tenemos todo cubierto.'
                    />
                    <Benefit
                        icon={icon3}
                        title='Actualización automática' 
                        text='Asegurando que tengas la información más reciente y precisa en todo momento, puedes confiar en que tus decisiones se basan en datos frescos y relevantes. Simplifica tu proceso y mantente un paso adelante con nosotros.'
                    />
                </div>

                <PopupButton
                    url='https://calendly.com/binahria-analytics/'
                    rootElement={document.getElementById("root")}
                    text={`${t('contact.schedule')} →`}
                />
            </div>
        </div>
    )
}

export default MarketplaceBody;
